/*** IMPORTS ***/
import React from 'react';

// custom hooks
import getCookies from '../customHook/getCookies';

// import formik and yup
import {Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
/********************************************************************************************** */

const ContactMe = () => {
    // valori iniziali form
    const form = {
        name: '',
        surname: '',
        email: '',
        phone: '',
        message: '',
        privacyChecked: false
    };

    // schema di validazione form
    const validationForm = Yup.object({
        name: Yup.string()
            .min(3, 'Il campo Nome deve contenere minimo 3 caratteri.')
            .max(35, 'Il Nome può contenere massimo 35 caratteri.')
            .required('Il campo Nome è richiesto.')
            .matches(/^[a-zA-Z]+$/, 'Il Nome può contenere solo lettere senza spazi.'),

        surname: Yup.string()
            .min(3, 'Il campo Cognome deve contenere minimo 3 caratteri.')
            .max(35, 'Il Cognome può contenere massimo 35 caratteri.')
            .required('Il campo Cognome è richiesto.')
            .matches(/^[a-zA-Z]+$/, 'Il Cognome può contenere solo lettere senza spazi.'),

        email: Yup.string()
            .email('Indirizzo Email non valido.')
            .required('Il campo Email è richiesto.'),

        phone: Yup.string()
            .required('Il campo Telefono è richiesto.')
            .max(10, 'Il numero di Telefono può contenere massimo 10 cifre numeriche.')
            .matches(/^(([+]|00)39)?((3[1-6][0-9]))(\d{7})$/, 'Il numero di Telefono deve essere valido e Italiano.'),

        message: Yup.string()
            .required('Il campo Messaggio è richiesto.'),

        privacyChecked: Yup.bool()
            .oneOf([true], 'È necessario accettare i Termini e le Condizioni.')
            .default(false)
    });

    // funzione per invio form contatti
    function onSubmitFormContact(values, actions) {
        // axios post api contact
        axios.post('/api/contact', {
            Api_token: getCookies('MyAppTokenApi'),
            form: values
        })
        .then((res) => {
            actions.resetForm(form);
            actions.setSubmitting(false);

            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Grazie per avermi contattato. Ti risponderò al più presto.",
                confirmButtonText: 'Chiudi',
                timer: 4000,
                timerProgressBar: true
            });
        })
        .catch((err) => {
            console.warn(err);
            actions.setSubmitting(false);

            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Impossibile completare l'operazione al momento. Per favore riprova tra qualche minuto.",
                confirmButtonText: 'Chiudi',
                timer: 4000,
                timerProgressBar: true
            });
        });
    }

    return (
        <section id='contact-me'>
            <div className='position-relative'>
                {/* titolo e sottotitolo */}
                <div className='row mb-5'>
                    <div className='col-12'>
                        <motion.h3 className='title animation-flicker-opacity'
                            initial={{y: -50, scale: 0.5}}
                            whileInView={{y: 0, scale: 1}}
                            transition={{duration: 0.6}}
                            viewport={{once: false}}
                        >
                            Hai un'idea? Scrivimi e trasformiamola insieme in un progetto reale e personalizzato.
                        </motion.h3>
                        <motion.p className='subtitle text-uppercase mb-0'
                            initial={{opacity: 0, y: 50 }}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.7, delay: 0.1}}
                            viewport={{once: false}}
                        >
                            Compila il modulo e ti risponderò al più presto per discutere il tuo progetto
                        </motion.p>
                    </div>
                </div>

                {/* form */}
                <div className='row gy-5'>
                    <Formik initialValues={form} validationSchema={validationForm}
                        onSubmit={(values, actions) => {
                            onSubmitFormContact(values, actions);
                        }}
                    >
                        {props => (
                            <motion.form onSubmit={props.handleSubmit} className='col-12 col-md-8'
                                initial={{opacity: 0, y: 50 }}
                                whileInView={{opacity: 1, y: 0}}
                                transition={{duration: 0.8, delay: 0.3}}
                                viewport={{once: false}}
                            >
                                {/* row inputs */}
                                <fieldset className='row gy-3 gx-5 mb-4'>
                                    {/* nome */}
                                    <div className='col-12 col-md-6 form-group'>
                                        <div className="form-floating">
                                            <input type="text" className={`form-control ${props.touched.name && props.errors.name ? 'invalid' : ''}`} id='name' name='name' value={props.values.name} placeholder="Nome" onChange={props.handleChange} onBlur={props.handleBlur} required autoComplete="off"/>
                                            <label htmlFor="name">Nome</label>
                                        </div>

                                        <ErrorMessage name="name" component="div" className='error-message'/>
                                    </div>

                                    {/* cognome */}
                                    <div className='col-12 col-md-6 form-group'>
                                        <div className="form-floating">
                                            <input type="text" className={`form-control ${props.touched.surname && props.errors.surname ? 'invalid' : ''}`} id='surname' name='surname' value={props.values.surname} placeholder="Cognome" onChange={props.handleChange} onBlur={props.handleBlur} required autoComplete="off"/>
                                            <label htmlFor="surname">Cognome</label>
                                        </div>

                                        <ErrorMessage name="surname" component="div" className='error-message'/>
                                    </div>

                                    {/* indirizzo e-mail */}
                                    <div className='col-12 col-md-6 form-group'>
                                        <div className="form-floating">
                                            <input type="email" className={`form-control ${props.touched.email && props.errors.email ? 'invalid' : ''}`} id='email' name='email' value={props.values.email} placeholder="Indirizzo e-mail" onChange={props.handleChange} onBlur={props.handleBlur} required autoComplete="off"/>
                                            <label htmlFor="email">Indirizzo e-mail</label>
                                        </div>

                                        <ErrorMessage name="email" component="div" className='error-message'/>
                                    </div>

                                    {/* telefono */}
                                    <div className='col-12 col-md-6 form-group'>
                                        <div className="form-floating">
                                            <input type="text" className={`form-control ${props.touched.phone && props.errors.phone ? 'invalid' : ''}`} id='phone' name='phone' value={props.values.phone} placeholder="Telefono" onChange={props.handleChange} onBlur={props.handleBlur} required autoComplete="off"/>
                                            <label htmlFor="phone">Telefono</label>
                                        </div>

                                        <ErrorMessage name="phone" component="div" className='error-message'/>
                                    </div>

                                    {/* message */}
                                    <div className='col-12 form-group'>
                                        <div className="form-floating">
                                            <textarea className={`form-control ${props.touched.message && props.errors.message ? 'invalid' : ''}`} placeholder="Messaggio" id='message' name='message' value={props.values.message} onChange={props.handleChange} onBlur={props.handleBlur} required></textarea>
                                            <label htmlFor="message">Messaggio</label>
                                        </div>

                                        <ErrorMessage name="message" component="div" className='error-message'/>
                                    </div>

                                    {/* check privacy policy */}
                                    <div className='col-12 form-group'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="privacyChecked" name='privacyChecked' checked={props.values.privacyChecked} onChange={props.handleChange} onBlur={props.handleBlur} required/>
                                            <label className="form-check-label" htmlFor="privacyChecked">
                                                Acconsento al trattamento dei dati personali 
                                                <a className='text-blue' href='/privacy-policy.html'> Privacy Policy.</a>
                                            </label>
                                        </div>
                
                                        <ErrorMessage name="privacyChecked" component="div" className='error-message'/>
                                    </div>
                                </fieldset>

                                {/* row btn actions */}
                                <div className="row">
                                    <div className='col-12'>
                                        {/* btn submit form */}
                                        <button type='submit' className={`btn-custom ${props.isSubmitting == true ? 'd-none' : ''}`} disabled={props.isSubmitting}>
                                            <div className='btn-contact-us'>
                                                <span className='text'>Invia</span>
                                                <span className='icon'>
                                                    <i className="fa-solid fa-arrow-right-long"></i>
                                                </span>
                                            </div>
                                        </button>

                                        {/* spinner caricamento invio */}
                                        <div className={`spinner-border text-secondary ${props.isSubmitting == false ? 'd-none' : ''}`} role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </motion.form>
                        )}
                    </Formik>

                    {/* img */}
                    <motion.div className='col-12 form-image d-md-none d-lg-block mx-auto' 
                        initial={{opacity: 0, scale: 0.5}}
                        whileInView={{opacity: 1, scale: 1}}
                        transition={{duration: 0.8, delay: 0.2}}
                        viewport={{once: false}}
                    >
                        <img src="/storage/uploads/images/icon-site/icon-svg.svg" alt='Simone Daglio logo' loading="lazy"/>
                    </motion.div>
                </div>
            </div>
        </section>
    );
}

export default ContactMe;