/*** IMPORTS ***/
import React from 'react';

// Components
import Skills from './Skills';
import Experiences from './Experiences';
import Formations from './Formations';
/******************************************* */

const About = () => {
    return (
        <section id='about'>
            {/* row info chi sono */}
            <div className='row flex-column-reverse flex-lg-row gy-4 gx-5 mb-5'>
                {/* foto personale */}
                <motion.div className='col-12 col-lg-4'
                    initial={{opacity: 0, x: -100}}
                    whileInView={{opacity: 1, x: 0}}
                    transition={{duration: 0.6, delay: 0.1}}
                    viewport={{once: false}}
                >
                    <figure className='about-photo'>
                        <img className='img-fluid' src={'/storage/uploads/images/my_photo.webp'} alt='Foto Simone Daglio' />
                    </figure>
                </motion.div>

                {/* testo */}
                <div className='col-12 col-lg-8'>
                    {/* titolo sezione */}
                    <motion.h3 className='title-section mb-3'
                        initial={{opacity: 0, x: -100}}
                        whileInView={{opacity: 1, x: 0 }}
                        transition={{duration: 0.6, delay: 0}}
                        viewport={{once: false}}
                    >
                        <span className='title-white'>Chi </span>
                        Sono
                    </motion.h3>

                    {/* testo sezione */}
                    <motion.p className='about-text'
                        initial={{opacity: 0, x: 100}}
                        whileInView={{opacity: 1, x: 0}}
                        transition={{duration: 0.7, delay: 0.1}}
                        viewport={{once: false}}
                    >
                        Sono Simone Daglio, uno sviluppatore software con una solida esperienza nel back-end e una passione per la creazione di soluzioni CRM e applicazioni web. Dedico il mio tempo non solo alla programmazione, ma anche all'esplorazione di nuove tecnologie per offrire prodotti sempre all'avanguardia.
                        <br/><br/>
                        Il mio approccio si basa su efficienza e innovazione, con una costante attenzione al miglioramento delle mie competenze. Questi principi guidano il mio lavoro, aiutandomi a sviluppare progetti di alta qualità che rispondono alle esigenze dei clienti.
                        <br/><br/>
                        Al di fuori del lavoro, sono un appassionato di supercar e motocross. Mi definisco determinato, metodico e ambizioso: caratteristiche che mi spingono a migliorarmi continuamente e a portare il massimo impegno in ogni progetto che affronto.
                    </motion.p>
                </div>
            </div>

            {/* row accordion (esperienze, skills, formazione) */}
            <div className='row mt-2'>
                <div className='col-12'>
                    <div className="accordion accordion-flush" id="accordionFlushOtherAbout">
                        {/* accordion esperienze */}
                        <motion.div className="accordion-item" 
                            initial={{opacity: 0, y: 50}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 1}}
                            viewport={{once: false}}
                        >
                            <h4 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseExperiences" aria-expanded="false" aria-controls="flush-collapseExperiences">
                                    <span className='accordion-button-text'>
                                        <i className="fa-solid fa-briefcase me-4"></i>
                                        Esperienze
                                    </span>
                                </button>
                            </h4>
                            <div id="flush-collapseExperiences" className="accordion-collapse collapse" data-bs-parent="#accordionFlushOtherAbout">
                                <div className="accordion-body">
                                    <Experiences/>
                                </div>
                            </div>
                        </motion.div>

                        {/* accordion skills */}
                        <motion.div className="accordion-item" 
                            initial={{opacity: 0, y: 50}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 1, delay: 0.1}}
                            viewport={{once: false}}
                        >
                            <h4 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSkills" aria-expanded="false" aria-controls="flush-collapseSkills">
                                    <span className='accordion-button-text'>
                                        <i className="fa-solid fa-code me-4"></i>
                                        Skills
                                    </span>
                                </button>
                            </h4>
                            <div id="flush-collapseSkills" className="accordion-collapse collapse" data-bs-parent="#accordionFlushOtherAbout">
                                <div className="accordion-body">
                                    <Skills/>
                                </div>
                            </div>
                        </motion.div>

                        {/* accordion formazioni */}
                        <motion.div className="accordion-item" 
                            initial={{opacity: 0, y: 50}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 1, delay: 0.15}}
                            viewport={{once: false}}
                        >
                            <h4 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFormations" aria-expanded="false" aria-controls="flush-collapseFormations">
                                    <span className='accordion-button-text'>
                                        <i className="fa-solid fa-graduation-cap me-4"></i>
                                        Formazione
                                    </span>
                                </button>
                            </h4>
                            <div id="flush-collapseFormations" className="accordion-collapse collapse" data-bs-parent="#accordionFlushOtherAbout">
                                <div className="accordion-body">
                                    <Formations/>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;