/*** IMPORTS ***/
import React, {useEffect} from 'react';

// redux
import {useDispatch, useSelector} from 'react-redux';
import {fetchExperiences} from '../redux/reducers/apiExperiences';

// components
import ErrorApi from './ErrorApi';
/*********************************************************************** */

const Experiences = () => {
    // redux
    const dispatch = useDispatch();
    const { loading, error, experiences } = useSelector((state) => state.experiences);

    // al primo render recupera le esperienze dal server
    useEffect(() => {
        dispatch(fetchExperiences());
    }, []);

    return (
        <div id="experiences">
            {!loading && !error.status ? (
                <ul className="row gy-5">
                    {experiences.map((experience) => (
                        <motion.li className="col-12 d-flex position-relative" key={experience.id} 
                            initial={{opacity: 0, y: 30}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.7}}
                            viewport={{once: false}}
                        >
                            {/* Linea di separazione per l'effetto timeline */}
                            <div className="vr position-absolute top-0 bottom-0 start-2 d-block"></div>

                            {/* Contenuto della singola esperienza */}
                            <div className="ps-4">
                                <p className="small text-primary mb-2">
                                    {(new Date(experience.date_start)).toLocaleString('default', { month: 'short',  year: 'numeric' }).replace(/^\w/, (c) => c.toUpperCase())}
                                    {experience.currently_serve_in_this_role == 0 ? ` - ${(new Date(experience.date_end)).toLocaleString('default', { month: 'short',  year: 'numeric' }).replace(/^\w/, (c) => c.toUpperCase())}` : ' - Presente'}
                                </p>
                                <h4 className="fs-5 text-white mb-2">{experience.job}</h4>
                                <p className="badge text-bg-secondary mb-3">{experience.company}</p>
                                <p className="text-secondary mb-0" dangerouslySetInnerHTML={{
                                    __html: experience.description.replace(/(\s*\r?\n\s*\r?\n\s*)/g, "<br/><br/>").replace(/(\s*\r?\n\s*)/g, "<br/>")
                                    }}
                                ></p>
                            </div>
                        </motion.li>
                    ))}
                </ul>
            ) : loading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-secondary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <ErrorApi message={error.message} />
            )}
        </div>
    );
}

export default Experiences;