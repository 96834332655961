/*** IMPORTS ***/
// Bootstrap
require('./bootstrap');

// Sweet Alert2
window.Swal = require('sweetalert2');

// React
import React from 'react';
import {createRoot} from 'react-dom/client';

// Redux
import {Provider} from 'react-redux';
import store from './front-end/redux/store';

// React Router
import Router from './front-end/router/Router';

// Framer Motion (GLOBALE)
import {motion} from 'framer-motion';
window.motion = motion;

// Report Web Vitals
import reportWebVitals from './front-end/utils/reportWebVitals';
/***************************************** */


/*** RENDER root React ***/
if (document.getElementById('root')) {
    const root = createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <Router />
            </Provider>
        </React.StrictMode>
    );
}
/***************************************** */

// Avvia la raccolta delle metriche
reportWebVitals((metric) => {
    // console.log(metric);
});