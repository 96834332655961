/*** IMPORTS ***/
import { createSlice } from "@reduxjs/toolkit";

// custom hooks
import getCookies from '../../customHook/getCookies';
/***************************************************** */

// initial state
const initialState = {
    loading: true,
    error: {
        status: false,
        message: ''
    },
    projects: [],
    pagination: {
        currentPage: 0,
        lastPage: 0,
        nextPageUrl: '',
        lastPageUrl: '',
        perPage: 0,
        totalItem: 0
    }
};

// api projects slice
const apiProjectsSlice = createSlice({
   name: 'apiProjects',
   initialState,
   reducers: {
    startLoading: (state) => {
        // reset
        state.loading = true;
        state.error.status = false;
        state.error.message = '';
        state.projects = [];
        state.pagination.currentPage = 0;
        state.pagination.lastPage = 0;
        state.pagination.nextPageUrl = '';
        state.pagination.lastPageUrl = '';
        state.pagination.perPage = '';
        state.pagination.totalItem = 0;
    },

    stopLoading: (state) => {
        state.loading = false;
    },

    catchError: (state, action) => {
        state.error.status = true;
        state.error.message = action.payload;
    },

    saveData: (state, action) => {
        // projects
        state.projects = action.payload.projects;

        // currentpage
        state.pagination.currentPage = action.payload.currentPage;

        // last page
        state.pagination.lastPage = action.payload.lastPage;

        // next page url
        state.pagination.nextPageUrl = action.payload.nextPageUrl;

        // last page url
        state.pagination.lastPageUrl = action.payload.lastPageUrl;

        // per page item
        state.pagination.perPage = action.payload.perPage;

        // total item
        state.pagination.totalItem = action.payload.totalItem;
    }
   }
});

// actions apiProjectsSlice
const {startLoading, stopLoading, catchError, saveData} = apiProjectsSlice.actions;

// fect projects axios
const fetchProjects = (page = 1) => (dispatch) => {
    // start loading
    dispatch(startLoading());

    axios.get('/api/projects', {
        params: {
            page: page,
            Api_token: getCookies('MyAppTokenApi')
        }
    })
    .then((res) => {
        // save data
        dispatch(saveData({
            projects: res.data.projects.data,
            currentPage: res.data.projects.current_page,
            lastPage: res.data.projects.last_page,
            nextPageUrl: res.data.projects.next_page_url,
            lastPageUrl: res.data.projects.last_page_url,
            perPage: res.data.projects.per_page,
            totalItem: res.data.projects.total
        }));
    })
    .catch((err) => {
        console.warn(err.response.data.message);

        // set error true
        dispatch(catchError(err.response.data.message));
    });

    // stop loading
    dispatch(stopLoading());
}

// export actions apiProjectsSlice
export {fetchProjects};

// export default reducer
const {reducer} = apiProjectsSlice;
export default reducer;