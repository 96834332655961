/*** IMPORTS ***/
import {createSlice} from '@reduxjs/toolkit';

// custom hooks
import getCookies from '../../customHook/getCookies';
/***************************************************** */

// initial state
const initialState = {
    loading: true,
    error: {
        status: false,
        message: ''
    },
    formations: [],
};

// api formations slice
const apiFormationsSlice = createSlice({
    name: 'apiFormations',
    initialState,
    reducers: {
        startLoading: (state) => {
            // reset
            state.loading = true;
            state.error.status = false;
            state.error.message = '';
            state.formations = [];
        },

        stopLoading: (state) => {
            state.loading = false;
        },

        catchError: (state, action) => {
            state.error.status = true;
            state.error.message = action.payload;
        },

        saveData: (state, action) => {
            // formations
            state.formations = action.payload;
        }
    }
});

// actions apiProjectsSlice
const { startLoading, stopLoading, catchError, saveData } = apiFormationsSlice.actions;

// fect formations axios
const fetchFormations = () => (dispatch) => {
    // start loading
    dispatch(startLoading());

    axios.get('/api/formations', {
        params: {
            Api_token: getCookies('MyAppTokenApi')
        }
    })
    .then((res) => {
        // save data
        dispatch(saveData(res.data.formations));
    })
    .catch((err) => {
        console.warn(err.response.data.message);

        // set error true
        dispatch(catchError(err.response.data.message));
    });

    // stop loading
    dispatch(stopLoading());
}

// export actions apiProjectsSlice
export { fetchFormations };

// export default reducer
const { reducer } = apiFormationsSlice;
export default reducer;