/*** IMPORTS ***/
import React, {useEffect} from 'react';

// redux
import {useDispatch, useSelector} from 'react-redux';
import {fetchFormations} from '../redux/reducers/apiFormations';

// components
import ErrorApi from './ErrorApi';
/*********************************************************************** */

const Formations = () => {
    // redux
    const dispatch = useDispatch();
    const {loading, error, formations} = useSelector((state) => state.formations);

    // al primo rendere recupera le formazioni dal server
    useEffect(() => {
        dispatch(fetchFormations());
    }, []);

    return (
        <div id='formations'>
            {/* conditional render list formations */}
            {!loading && !error.status ? (
                <ul className="row gy-5">
                    {formations.map((formation) => (
                        <motion.li className="col-12 d-flex position-relative" key={formation.id}
                            initial={{opacity: 0, y: 30}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.7}}
                            viewport={{once: false}}
                        >
                            {/* Linea di separazione per l'effetto timeline */}
                            <div className="vr position-absolute top-0 bottom-0 start-2 d-block"></div>

                            {/* Contenuto della singola formazione */}
                            <div className="ps-4">
                                <p className="small text-primary mb-2">
                                    {(new Date(formation.date_start)).toLocaleString('default', { month: 'short',  year: 'numeric' }).replace(/^\w/, (c) => c.toUpperCase())}
                                    {formation.date_end != null ? ` - ${(new Date(formation.date_end)).toLocaleString('default', { month: 'short',  year: 'numeric' }).replace(/^\w/, (c) => c.toUpperCase())}` : ''}
                                </p>
                                <h4 className="fs-5 text-white mb-2">{formation.course}</h4>
                                <p className="badge text-bg-secondary mb-3">{formation.school}</p>
                                <p className="text-secondary mb-0" dangerouslySetInnerHTML={{
                                    __html: formation.description.replace(/(\s*\r?\n\s*\r?\n\s*)/g, "<br/><br/>").replace(/(\s*\r?\n\s*)/g, "<br/>")
                                    }}
                                ></p>
                            </div>
                        </motion.li>
                    ))}
                </ul>
            ) : !loading && error.status ? (
                <ErrorApi message={error.message} />
            ) : (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-secondary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Formations;