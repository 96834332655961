/*** IMPORTS ***/
import { createSlice } from "@reduxjs/toolkit";

// custom hooks
import getCookies from '../../customHook/getCookies';
/****************************************************** */

// initial state
const initialState = {
    loading: true,
    error: {
        status: false,
        message: ''
    },
    skills: [],
};

// api skills slice
const apiSkillsSlice = createSlice({
    name: 'apiSkills',
    initialState,
    reducers: {
        startLoading: (state) => {
            // reset
            state.loading = true;
            state.error.status = false;
            state.error.message = '';
            state.skills = [];
        },

        stopLoading: (state) => {
            state.loading = false;
        },

        catchError: (state, action) => {
            state.error.status = true;
            state.error.message = action.payload;
        },

        saveData: (state, action) => {
            // skills
            state.skills = action.payload;
        }
    }
});

// actions apiProjectsSlice
const { startLoading, stopLoading, catchError, saveData } = apiSkillsSlice.actions;

// fect skills axios
const fetchSkills = () => (dispatch) => {
    // start loading
    dispatch(startLoading());

    axios.get('/api/skills', {
        params: {
            Api_token: getCookies('MyAppTokenApi')
        }
    })
    .then((res) => {
        // save data
        dispatch(saveData(res.data.skills));
    })
    .catch((err) => {
        console.warn(err.response.data.message);

        // set error true
        dispatch(catchError(err.response.data.message));
    });

    // stop loading
    dispatch(stopLoading());
}

// export actions apiProjectsSlice
export { fetchSkills };

// export default reducer
const { reducer } = apiSkillsSlice;
export default reducer;