/*** IMPORTS ***/
import { configureStore } from "@reduxjs/toolkit";

// reducers
import windowLoadReducer from './reducers/windowLoad';
import headerReducer from "./reducers/header";
import apiFormationsReducer from './reducers/apiFormations';
import apiExperiencesReducer from './reducers/apiExperiences';
import apiSkillsReducer from './reducers/apiSkills';
import apiProjectsReducer from './reducers/apiProjects';
/*************************************************************** */

// store 
const store = configureStore({
    reducer: {
        windowLoad: windowLoadReducer,
        header: headerReducer,
        formations: apiFormationsReducer,
        experiences: apiExperiencesReducer,
        skills: apiSkillsReducer,
        projects: apiProjectsReducer
    }
});

// export store
export default store;