/*** IMPORTS ***/
import React, {useEffect} from 'react';

// Components
import Hero from '../components/Hero';
import About from '../components/About';
import Projects from '../components/Projects';
import ContactMe from '../components/ContactMe';
/************************************************ */

const HomePage = () => {
    // al primo render scrolla la pagina all'inizio
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id='home-page'>
            {/* Hero */}
            <Hero/>

            <div className='container'>
                {/* Chi Sono */}
                <About/>

                {/* Progetti */}
                <Projects/>

                {/* Contattami */}
                <ContactMe/>
            </div>
        </div>
    );
}

export default HomePage;