/*** IMPORTS ***/
import React, {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

// Animated Cursor
import AnimatedCursor from "react-animated-cursor";

// components
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
/******************************************* */

const PageLayout = () => {
    // controllo se il dispositivo è mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    useEffect(() => {
        // Inizio della misurazione per il layout della pagina
        performance.mark('start-rendering-page-layout');

        // Fine della misurazione (quando il layout è completamente renderizzato)
        return () => {
            performance.mark('end-rendering-page-layout');
            performance.measure('page-layout-render-time', 'start-rendering-page-layout', 'end-rendering-page-layout');
            const measures = performance.getEntriesByName('page-layout-render-time');
            // // console.log('Durata del rendering del layout:', measures[0].duration, 'ms');
        }
    }, []);
    
    return (
        <>
            {/* Cursore animato */}
            {!isMobile && <AnimatedCursor
                innerSize={10}
                outerSize={32}
                color='255, 255, 255'
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={2}
                innerStyle={{
                    zIndex: 999999
                }}
                outerStyle={{
                    border: '0.5px solid #ffffff',
                    backgroundColor: 'rgba(255, 255, 255, 0.0)',
                    zIndex: 999999
                }}
                hasBlendMode={true}
                trailingSpeed={1}
            />}

            {/* Header */}
            <Navbar/>

            {/* rotte nidificate tramite router */}
            <main>
                <Outlet/>
            </main>

            {/* footer */}
            <Footer/>
        </>
    );
}

export default PageLayout;