/*** IMPORTS ***/
import React, {useEffect} from 'react';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {fetchSkills} from '../redux/reducers/apiSkills';

// Components
import ErrorApi from './ErrorApi';
/*************************************************************** */

const Skills = () => {
    // redux
    const dispatch = useDispatch();
    const {loading, error, skills} = useSelector((state) => state.skills);

    // al primo rendere recupera le skills dal server
    useEffect(() => {
        dispatch(fetchSkills());
    }, []);

    return (
        <div id='skills'>
            {/* conditional swiper skills */}
            {!loading && !error.status ? (
                <div className='row'>
                    {/* front end */}
                    <ul className='col-12 d-flex flex-wrap gap-4'>
                        {skills.map((skill) => {
                            return(
                                <motion.li className='col-auto col-md-1 skill-card' key={skill.id}
                                    initial={{opacity: 0, y: 30}}
                                    whileInView={{opacity: 1, y: 0}}
                                    transition={{duration: 0.8}}
                                    viewport={{once: false}}
                                >
                                    <figure className='logo'>
                                        <img src={`/storage/${skill.logo}`} alt={skill.name} title={skill.name} loading="lazy"/>
                                    </figure>
                                    <p className='skill-name'>{skill.name}</p>
                                </motion.li>
                            );
                        })}
                    </ul>
                </div>
            ) : !loading && error.status ? (
                <ErrorApi message={error.message} />
            ) : ( 
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-secondary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Skills;