/*** IMPORTS ***/
import React, {useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

// redux
import {useDispatch, useSelector} from 'react-redux';
import {setIsLoad} from '../redux/reducers/windowLoad';

// components
import PageLoading from '../components/PageLoading';

// layouts
import PageLayout from '../layouts/PageLayout';

// pages
import Homepage from '../pages/HomePage';
import NotFoundPage from '../pages/NotFoundPage';
/******************************************************************************** */


const ReactIndex = () => {
    // redux
    const dispatch = useDispatch();
    const {isLoad} = useSelector((state) => state.windowLoad);

    useEffect(() => {
        $(document).ready(function () {
            setTimeout(() => {
                dispatch(setIsLoad(true));
            }, 1300);
        });
    }, []);

    return (
        <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
            {/* if page is load false */}
            {isLoad === false ? (
                <PageLoading/>
            ) : (
                <Routes>
                    {/* rotte element pageLayout header footer */}
                    <Route element={<PageLayout/>}>
                        <Route path='/' element={<Homepage />} />
                    </Route>

                    {/* error page without header footer */}
                    <Route path='*' element={<NotFoundPage />} />
                </Routes>
            )}
        </Router>
    );
}

export default ReactIndex;