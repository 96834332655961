/*** IMPORTS ***/
import React from 'react';
/************************************ */

const PageLoading = () => {
    return (
        <div id='page-loading'>
            <div className='container h-100'>
                <div className='row h-100 justify-content-center align-items-center'>
                    <div className='col-12'>
                        <div className="loader"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageLoading;