/*** IMPORTS ***/
import { createSlice } from "@reduxjs/toolkit";
/*********************************************** */

// initial state
const initialState = {
    btnMenuIsActive: false,
    menuLinks: [
        {
            id: 1,
            name: "Home",
            href: "#home-page"
        },
        {
            id: 2,
            name: "Chi Sono",
            href: "#about"
        },
        {
            id: 3,
            name: "Progetti",
            href: "#projects"
        },
        {
            id: 4,
            name: "Contattami",
            href: "#contact-me"
        }
    ],
    idActiveLink: 1
};

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setBtnMenuIsActive: (state) => {
            state.btnMenuIsActive = !state.btnMenuIsActive;

            if(state.btnMenuIsActive) {
                document.querySelector('#root').style.overflowY = "hidden";
            } else {
                document.querySelector('#root').style.overflowY = "auto";
            }
        },
        setIdActiveLink: (state, action) => {
            state.idActiveLink = action.payload;
        }
    }
});

// export actions headerSlice
export const {setBtnMenuIsActive, setIdActiveLink} = headerSlice.actions;

// export reducer
const {reducer} = headerSlice;
export default reducer;