// get cookies by name
const getCookies = (name) => {
    // cookie object
    let cookie = {};

    // split foreach cookies document
    document.cookie.split(';').forEach(function (el) {
        let [key, value] = el.split('=');
        cookie[key.trim()] = value;
    });
    
    // return cookie by name
    return cookie[name];
};

export default getCookies;