/*** IMPORTS ***/
import React, {useEffect, useRef} from 'react';

// redux
import {useDispatch, useSelector} from 'react-redux';
import {fetchProjects} from '../redux/reducers/apiProjects';

// components
import ErrorApi from './ErrorApi';
/****************************************************************** */


const Projects = () => {
    // redux
    const dispatch = useDispatch();
    const {loading, error, projects, pagination} = useSelector((state) => state.projects);

    // al primo render recupera i progetti dal server
    useEffect(() => {
        dispatch(fetchProjects());
    }, []);

    // projects div ref
    const refProjectsDiv = useRef();

    // next page
    const nextPage = () => {
        if(pagination.currentPage + 1 <= pagination.lastPage) {
            // ref projects div scroll
            refProjectsDiv.current.scrollIntoView({
                top: refProjectsDiv.offsetTop,
                left: 0,
                behavior: "smooth",
            });

            // fetch projects
            dispatch(fetchProjects(pagination.currentPage + 1));
        }
    }

    // prev page
    const prevPage = () => {
        if (pagination.currentPage - 1 !== 0) {
            // ref projects div scroll
            refProjectsDiv.current.scrollIntoView({
                top: refProjectsDiv.offsetTop,
                left: 0,
                behavior: "smooth",
            });

            // fetch projects
            dispatch(fetchProjects(pagination.currentPage - 1));
        }  
    }

    return (
        <section id='projects' ref={refProjectsDiv}>
            {/* title */}
            <div className="row mb-5">
                <div className='col-12'>
                    <motion.h3 className='title-section'
                        initial={{opacity: 0, x: -100}} 
                        whileInView={{opacity: 1, x: 0}}
                        transition={{duration: 0.6, delay: 0}} 
                        viewport={{once: false}}
                    >
                        <span className='title-white'>I Miei </span>
                        Progetti
                    </motion.h3>
                </div>
            </div>

            {/* list projects conditional render */}
            {!loading && !error.status ? (
                <>
                    <ul className='row gy-5 gx-5'>
                        {projects.map((project, index) => {
                            return(
                                <motion.li className='col-12 col-md-6'
                                    key={project.id}
                                    initial={{opacity: 0, y: 150}}
                                    whileInView={{opacity: 1, y: 0}}
                                    transition={{duration: 0.8, delay: Math.min(index * 0.065, 0.3)}}
                                    viewport={{once: false}}
                                >
                                    <article className='card'>
                                        {/* immagine progetto */}
                                        <img className="card-img-top" src={`storage/${project.img}`} alt={project.name} title={project.name} loading="lazy"/>

                                        {/* body */}
                                        <div className="card-body px-0">
                                            <h5 className="card-title">{project.name}</h5>
                                            <p className="card-text text-truncate-2">{project.description}</p>
                                            
                                            {/* btn modal dettagli progetto */}
                                            <button type="button" className="btn btn-dark hover-animate" data-bs-toggle="modal" data-bs-target={`#modalProject${project.id}`}>
                                                Dettagli
                                                <i className="fa-solid fa-arrow-right-long ms-3"></i>
                                            </button>

                                            {/* modal dettagli progetto */}
                                            <div className="modal fade" id={`modalProject${project.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`modalProject${project.id}Label`} aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            {/* nome progetto */}
                                                            <h1 className="modal-title fs-5 text-uppercase" id={`modalProject${project.id}Label`}>
                                                                {project.name}
                                                            </h1>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            {/* immagine progetto */}
                                                            <img className="img-fluid mb-4 shadow" src={`storage/${project.img}`} alt={project.name}/>

                                                            {/* descrizione */}
                                                            <p>{project.description}</p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            {/* btn chiudi modal */}
                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                                                Chiudi
                                                                <i className="fa-regular fa-circle-xmark ms-2"></i>
                                                            </button>

                                                            {/* btn visualizza sito */}
                                                            <a className={`btn btn-primary ${project.demo == null ? 'd-none' : ''}`} href={project.demo} alt={`${project.name} Visita Sito`} target='_blank'>
                                                                Visita il Sito
                                                                <i className="fa-solid fa-earth-europe ms-2"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </motion.li>
                            );
                        })}
                    </ul>

                    {/* pulsanti paginazione */}
                    <div className={`row justify-content-between mt-5 ${pagination.lastPage == 1 ? 'd-none' : ''}`}>
                        <div className='col-auto'>
                            <button type='button' className={`btn btn-sm btn-primary ${pagination.currentPage == 1 ? 'disabled' : ''}`} onClick={() => prevPage()}>
                                <i className="fa-solid fa-caret-left me-2"></i>
                                Indietro
                            </button>
                        </div>
                        <div className='col-auto'>
                            <button type='button' className={`btn btn-sm btn-primary ${pagination.currentPage == pagination.lastPage ? 'disabled' : ''}`} onClick={() => nextPage()}>
                                Avanti
                                <i className="fa-solid fa-caret-right ms-2"></i>
                            </button>
                        </div>
                    </div>
                </>
            ) : !loading && error.status ? (
                <ErrorApi message={error.message}/>
            ) : (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-secondary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </section>
    );
}

export default Projects;