/*** IMPORTS ***/
import {onCLS, onFID, onLCP, onFCP, onTTFB} from 'web-vitals';
/***************************************** */

const reportWebVitals = (onPerfEntry) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        if (window.gtag instanceof Function) {
            // Invia la metrica a Google Analytics tramite gtag
            const sendToAnalytics = (metric) => {  
                window.gtag('event', metric.name, {
                    event_category: 'Web Vitals',
                    value: metric.value,       // Il valore della metrica
                    event_label: metric.id,     // L'ID unico della metrica
                    non_interaction: true       // Evita che questo evento influenzi la bounce rate
                });
            }
    
            // Registra le metriche
            onCLS(sendToAnalytics);  // Cumulative Layout Shift
            onFID(sendToAnalytics);  // First Input Delay
            onLCP(sendToAnalytics);  // Largest Contentful Paint
            onFCP(sendToAnalytics);  // First Contentful Paint
            onTTFB(sendToAnalytics); // Time to First Byte
        }
    }
}

export default reportWebVitals;