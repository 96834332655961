/*** IMPORTS ***/
import React from 'react';
/*********************************************************************** */

const Hero = () => {
    return (
        <div id='hero-container'>
            {/* hero bg */}
            <motion.div className='hero-bg'
                initial={{opacity: 0, scale: 1.1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 1.5, delay: 0.5}}
            >
                <img src="/storage/uploads/images/hero_bg.webp" alt='Sfondo decorativo software crm'/>
            </motion.div>

            {/* hero content */}
            <div className='container hero-content'>
                {/* row text */}
                <div className='row'>
                    <div className='col-12 d-flex justify-content-md-end'>
                        <motion.p className='hero-text-1'
                            initial={{opacity: 0, y: -50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 1, delay: 0.6}}
                        >
                            Sviluppo soluzioni CRM e applicazioni web performanti e scalabili, combinando competenze full-stack e 
                            tecnologie all'avanguardia. Il mio obiettivo è creare esperienze digitali intuitive e innovative che 
                            soddisfino le esigenze del tuo business.
                        </motion.p>
                    </div>
                </div>

                {/* row text 2 */}
                <div className='row justify-content-between align-items-end gy-3 mb-3'>
                    {/* hero text 2 */}
                    <div className='col-12 col-md-auto'>
                        <motion.h1 className='hero-text-2'
                            initial={{opacity: 0, y: 30}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.8, delay: 0.8}}
                        >
                            Simone
                        </motion.h1>
                    </div>

                    {/* btn contatti */}
                    <div className='col-12 col-md-auto'>
                        <motion.button type='button' className='btn-custom'
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.5, delay: 1}}
                        >
                            <a className='btn-contact-us' href='#contact-me'>
                                <span className='text'>Contatti</span>
                                <span className='icon'>
                                    <motion.i className="fa-solid fa-arrow-right-long" />
                                </span>
                            </a>
                        </motion.button>
                    </div>
                </div>

                {/* row text 3 */}
                <div className='row'>
                    <div className='col-12'>
                        <motion.h2 className='hero-text-3'
                            initial={{opacity: 0, y: 30}}
                            animate={{opacity: 0.5, y: 0}}
                            transition={{duration: 0.8, delay: 1.2}}
                        >
                            Daglio
                        </motion.h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;