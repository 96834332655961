/*** IMPORTS ***/
import { createSlice } from "@reduxjs/toolkit";
/*********************************************** */

// initial state
const initialState = {
    isLoad: false
}

// window load slice
const windowLoadSlice = createSlice({
    name: 'windowLoad',
    initialState,
    reducers: {
        setIsLoad: (state, action) => {
            state.isLoad = action.payload;
        }
    }
});

// export set isload action
export const {setIsLoad} = windowLoadSlice.actions;

// export default reducer window load slice
const {reducer} = windowLoadSlice
export default reducer;