/*** IMPORTS ***/
import { createSlice } from '@reduxjs/toolkit';

// custom hooks
import getCookies from '../../customHook/getCookies';
/****************************************************** */

// initial state
const initialState = {
    loading: true,
    error: {
        status: false,
        message: ''
    },
    experiences: [],
};

// api formations slice
const apiExperiencesSlice = createSlice({
    name: 'apiExperiences',
    initialState,
    reducers: {
        startLoading: (state) => {
            // reset
            state.loading = true;
            state.error.status = false;
            state.error.message = '';
            state.experiences = [];
        },

        stopLoading: (state) => {
            state.loading = false;
        },

        catchError: (state, action) => {
            state.error.status = true;
            state.error.message = action.payload;
        },

        saveData: (state, action) => {
            // experiences
            state.experiences = action.payload;
        }
    }
});

// actions apiExperiences
const { startLoading, stopLoading, catchError, saveData } = apiExperiencesSlice.actions;

// fect formations axios
const fetchExperiences = () => (dispatch) => {
    // start loading
    dispatch(startLoading());

    axios.get('/api/experiences', {
        params: {
            Api_token: getCookies('MyAppTokenApi')
        }
    })
    .then((res) => {
        // save data
        dispatch(saveData(res.data.experiences));
    })
    .catch((err) => {
        console.warn(err.response.data.message);

        // set error true
        dispatch(catchError(err.response.data.message));
    });

    // stop loading
    dispatch(stopLoading());
}

// export actions apiProjectsSlice
export { fetchExperiences };

// export default reducer
const { reducer } = apiExperiencesSlice;
export default reducer;