/*** IMPORTS ***/
import React from 'react';
/**************************** */

const ErrorApi = ({message}) => {
  return (
    <div className='error py-3 px-3 border border-blue max-w-xs rounded-md'>
        <h4 className='uppercase text-blue font-bold text-sm font-jura'>{message}</h4>
    </div>
  )
}

export default ErrorApi;