/*** IMPORTS ***/
import React from 'react';
import {Link} from 'react-router-dom';
/********************************************* */

const NotFoundPage = () => {
    return (
        <div id='not-found-page' className='h-100'>
            <div className='container h-100'>
                <div className='row h-100 justify-content-center align-items-center'>
                    <div className='col-12 text-center'>
                        <h1 className='error-code'>404</h1>
                        <h2 className='title mb-4'>Page not found</h2>

                        <button type='button' className='btn-custom mx-auto'>
                            <Link className='btn-contact-us' to="/">
                                <span className='text'>HomePage</span>
                                <span className='icon'>
                                    <motion.i className="fa-solid fa-arrow-right-long" />
                                </span>
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFoundPage;