/*** IMPORTS ***/
import React from 'react';

// redux
import {useSelector, useDispatch} from 'react-redux';
import {setIdActiveLink} from '../redux/reducers/header';
/************************************************ */

const Footer = () => {
    // redux
    const dispatch = useDispatch();
    const {menuLinks} = useSelector((state) => state.header);

    return (
        <footer className='py-4'>
            <div className='container'>
                {/* row details */}
                <div className="row gy-5 mb-5">
                    {/* logo */}
                    <div className="col-12 col-md-4">
                        <a href='/' className='logo-md'>
                            <img src="/storage/uploads/images/icon-site/icon-svg.svg" alt='Simone Daglio logo' loading='lazy'/>
                        </a>
                    </div>

                    {/* menù links */}
                    <div className="col-12 col-md-4">
                        <h4 className='text-secondary fs-6 mb-3'>Menù</h4>

                        <ul className='navbar-nav gap-2'>
                            {menuLinks.map((link) => {
                                return(
                                    <li className='nav-item' key={link.id}>
                                        <a className='link-light fs-6' onClick={() => dispatch(setIdActiveLink(link.id))} href={link.href}>
                                            {link.name}
                                        </a>
                                    </li>
                                );
                            })}
                            <li className='nav-item'>
                                <a className='link-light fs-6' href='/privacy-policy.html'>Privacy Policy</a>
                            </li>
                        </ul>
                    </div>

                    {/* socials */}
                    <div className='col-12 col-md-4'>
                        <h4 className='text-secondary fs-6 mb-3'>Socials</h4>

                        <ul className='navbar-nav flex-row gap-4'>
                            <li className='nav-item'>
                                <a target="_blank" href='https://www.linkedin.com/in/simone-daglio-74419b235/?trk=public_profile_samename-profile&originalSubdomain=it' aria-label="Visita il profilo Linkedin di Simone Daglio">
                                    <i className="fa-brands fa-linkedin fs-2 link-light"></i>
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a target="_blank" href='https://www.instagram.com/simone_daglio/' aria-label="Visita il profilo Instagram di Simone Daglio">
                                    <i className="fa-brands fa-instagram fs-2 link-light"></i>
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a target="_blank" href='https://github.com/Simone823' aria-label="Visita il profilo Github di Simone Daglio">
                                    <i className="fa-brands fa-square-github fs-2 link-light"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* row copyright */}
                <div className='row'>
                    <div className='col-12'>
                        <h6 className='text-secondary text-center fs-7 mb-0'>
                            &copy; Copyright {new Date().getFullYear()} | Simone Daglio
                        </h6>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;