/*** IMPORTS ***/
import React from 'react';

// redux
import {useDispatch, useSelector} from 'react-redux';
import {setBtnMenuIsActive, setIdActiveLink} from '../redux/reducers/header';
/*************************************************************** */

const MobileMenu = () => {
    // redux
    const dispatch = useDispatch();
    const {btnMenuIsActive, menuLinks, idActiveLink} = useSelector((state) => state.header);

    return (
        <div id='mobile-menu' className={`${btnMenuIsActive === true ? 'active' : ''}`}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <ul className='navbar-nav'>
                            {menuLinks.map((link) => {
                                return (
                                    <li key={link.id}>
                                        <a className={`link-light text-uppercase ${idActiveLink == link.id ? 'active' : ''}`} href={link.href} alt={link.name} onClick={() => {dispatch(setBtnMenuIsActive()); dispatch(setIdActiveLink(link.id))}}>
                                            {link.name}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;