/*** IMPORTS ***/
import React from 'react';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {setBtnMenuIsActive, setIdActiveLink} from '../redux/reducers/header';

// Components
import MobileMenu from './MobileMenu';
/************************************************************************************** */

const NavBar = () => {
    // redux
    const {btnMenuIsActive, menuLinks, idActiveLink} = useSelector((state) => state.header);
    const dispatch = useDispatch();

    return (
        <header className="py-2">
            <div className='container d-flex align-items-center justify-content-between'>
                {/* nav logo */}
                <motion.a className='nav-logo' href='/'
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                >
                    <img src="/storage/uploads/images/icon-site/icon-svg.svg" alt='Simone Daglio logo'/>
                </motion.a>

                {/* btn menu */}
                <motion.button type="button" className={`hamburger hamburger--slider d-block d-md-none ${btnMenuIsActive ? 'is-active' : ''}`}
                    onClick={() => dispatch(setBtnMenuIsActive())}
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6, delay: 0.2}}
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </motion.button>

                {/* list links menu */}
                <ul className='navbar-nav flex-row d-none d-md-flex'>
                    {menuLinks.map((link, index) => (
                        <motion.li className='nav-item' key={link.id}
                            initial={{opacity: 0, y: -50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.4, delay: index * 0.18 + 0.4}}
                        >
                            <a onClick={() => dispatch(setIdActiveLink(link.id))} href={link.href} className={`nav-link link-light text-uppercase fs-6 ${idActiveLink === link.id ? 'active' : ''}`}>
                                {link.name}
                            </a>
                        </motion.li>
                    ))}
                </ul>

                {/* mobile menu */}
                <MobileMenu/>
            </div>
        </header>
    );
}

export default NavBar;